// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {};

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {};

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: "CBP 1.0", // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: require("@/assets/images/logo/logo.png"), // Will update logo in navigation menu (Branding)
    // api endpoint
    remote_server_url: "http://127.0.0.1:8000/",
    api_endpoint: "http://127.0.0.1:8000/api/",
    api_endpoint_access_token: "0",
    // user infos
    userPermissions: null,
    // other
    form_designer_url: "http://localhost:8081/",
  },
  // api endpoint
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    routerTransition: "slide-fade", // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: "vertical", // vertical, horizontal
    contentWidth: "full", // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "sticky", // static , sticky , floating, hidden
      backgroundColor: "primary", // BS color options [primary, success, etc]
    },
    footer: {
      type: "static", // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true,
  },
};
