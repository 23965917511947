const routesFormated = [
  {
    path: "/dashboard/default",
    name: "dashboard-default",
    component: () => import("@/views/dashboard/analytics/Analytics.vue"),
    meta: {
      resource: "ACL",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
  {
    path: '/tableau-de-bord',
    name: 'access-control',
    component: () => import("@/views/dashboard/VipDashboard.vue"),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: "/",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/reset-password-v1",
    name: "auth-refresh",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v1.vue"),
    meta: {
      layout: "full",
      resource: "Auth", 
      action: 'read',
    },
  },
  {
    path: "/pages/authentication/login-v1",
    name: "auth-login-v1",
    component: () => import("@/views/pages/authentication/Login-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/login-v2",
    name: "auth-login-v2",
    component: () => import("@/views/pages/authentication/Login-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/register/:referer",
    name: "auth-register",
    component: () => import("@/views/pages/authentication/Register.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/register-v1",
    name: "auth-register-v1",
    component: () => import("@/views/pages/authentication/Register-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/register-v2",
    name: "auth-register-v2",
    component: () => import("@/views/pages/authentication/Register-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  /* {
    path: "/transaction/payment",
    name: "operation-payment",
    component: () => import("@/views/transaction/Controller.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  }, */
  {
    path: "/pages/authentication/forgot-password-v1",
    name: "auth-forgot-password-v1",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/forgot-password-v2",
    name: "auth-forgot-password-v2",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v2.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/authentication/reset-password-v1",
    name: "auth-reset-password-v1",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/reset-password-v2",
    name: "auth-reset-password-v2",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/pages/miscellaneous/ComingSoon.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/miscellaneous/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/error",
    name: "misc-error",
    component: () => import("@/views/pages/miscellaneous/Error.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/account-setting",
    name: "pages-account-setting",
    component: () => import("@/views/pages/account-setting/AccountSetting.vue"),
    meta: {
      pageTitle: "Mon Profile",
      breadcrumb: [
        {
          text: "Paramétrer",
          active: true,
        }
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: "/pages/profile",
    name: "pages-profile",
    component: () => import("@/views/pages/profile/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: "/apps/calendar",
    name: "apps-calendar",
    component: () => import("@/views/apps/calendar/Calendar.vue"),
  },
  {
    path: "/components/calendar",
    name: "components-calendar",
    component: () => import("@/views/components/calendar/Calendar.vue"),
    meta: {
      pageTitle: "Calendar",
      breadcrumb: [
        {
          text: "Components",
        },
        {
          text: "Calendar",
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: "/apps/email",
    name: "apps-email",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
    },
  },
  {
    path: "/apps/email/:folder",
    name: "apps-email-folder",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)
      )
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/email/label/:label",
    name: "apps-email-label",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["personal", "company", "important", "private"].includes(
          to.params.label
        )
      )
        next();
      else next({ name: "error-404" });
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/todo",
    name: "apps-todo",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
    },
  },
  {
    path: "/apps/todo/:filter",
    name: "apps-todo-filter",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    beforeEnter(to, _, next) {
      if (["important", "completed", "deleted"].includes(to.params.filter))
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/todo/tag/:tag",
    name: "apps-todo-tag",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    beforeEnter(to, _, next) {
      if (["team", "low", "medium", "high", "update"].includes(to.params.tag))
        next();
      else next({ name: "error-404" });
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/chat",
    name: "apps-chat",
    component: () => import("@/views/apps/chat/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/e-commerce/shop",
    name: "apps-e-commerce-shop",
    component: () =>
      import("@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      pageTitle: "Shop",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/wishlist",
    name: "apps-e-commerce-wishlist",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue"
      ),
    meta: {
      pageTitle: "Wishlist",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Wishlist",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/checkout",
    name: "apps-e-commerce-checkout",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
      ),
    meta: {
      pageTitle: "Checkout",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Checkout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/:slug",
    name: "apps-e-commerce-product-details",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue"
      ),
    meta: {
      pageTitle: "Product Details",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
        {
          text: "Product Details",
          active: true,
        },
      ],
    },
  },
 {
    path: "/notification",
    name: "notification-list",
    component: () => import("@/views/notification/Index.vue"),
    meta: {
      pageTitle: "Notifications",
      breadcrumb: [
        {
          text: "Alerts",
          active: true,
        }
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: "/notification/notification-add-new",
    name: "notification-add-new",
    component: () => import("@/views/notification/Controller.vue"),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },
  {
    path: "/apps/users/view/:id",
    name: "apps-users-view",
    component: () => import("@/views/apps/user/users-view/UsersView.vue"),
  },
  {
    path: "/apps/users/edit/:id",
    name: "apps-users-edit",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
  },

  // Invoice
  {
    path: "/apps/invoice/list",
    name: "apps-invoice-list",
    component: () =>
      import("@/views/apps/invoice/invoice-list/InvoiceList.vue"),
  },
  {
    path: "/apps/invoice/preview/:id",
    name: "apps-invoice-preview",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoicePreview.vue"),
  },
  {
    path: "/apps/invoice/add/",
    name: "apps-invoice-add",
    component: () => import("@/views/apps/invoice/invoice-add/InvoiceAdd.vue"),
  },
  {
    path: "/apps/invoice/edit/:id",
    name: "apps-invoice-edit",
    component: () =>
      import("@/views/apps/invoice/invoice-edit/InvoiceEdit.vue"),
  },
  {
    path: "/table/bs-table",
    name: "table-bs-table",
    component: () => import("@/views/table/bs-table/Table.vue"),
    meta: {
      pageTitle: "BS Table",
      breadcrumb: [
        {
          text: "Table",
        },
        {
          text: "BS Table",
          active: true,
        },
      ],
    },
  },
  {
    path: "/table/vue-good-table",
    name: "table-good-table",
    component: () => import("@/views/table/vue-good-table/GoodTable.vue"),
    meta: {
      pageTitle: "Good Table",
      breadcrumb: [
        {
          text: "Table",
        },
        {
          text: "Good Table",
          active: true,
        },
      ],
    },
  },
  {
    path: "/components/modal",
    name: "components-modal",
    component: () => import("@/views/components/modal/Modal.vue"),
    meta: {
      pageTitle: "Modal",
      breadcrumb: [
        {
          text: "Components",
        },
        {
          text: "Modal",
          active: true,
        },
      ],
    },
  },
  
  // for maintenance only -------------------------------------------------------------
];

// offline get
const userData = JSON.parse(localStorage.getItem("userData"));

// Same as above
function isset(ref) {
  return typeof ref !== "undefined";
}

/* console.log("count 10 sec------------va");
setTimeout(function () {
  console.log("10 sec------------passed");
}, 10000); */

console.log("router begin imports routes------------va");

if (userData !== null) {
  if (userData.departmentData === undefined) {
    localStorage.removeItem("userData");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location = "/";
  } else {
    // const departmentsPermissions = JSON.parse(userData.departmentData);
    const departmentsPermissions = userData.departmentData;
    // console.log(departmentsPermissions);

    ////////////////////////////////////////////////////////////
    console.log("departmentsPermissions");
    console.log(departmentsPermissions);

    for (let index = 0; index < departmentsPermissions.length; index++) {
      const department = departmentsPermissions[index];
      const modules = departmentsPermissions[index].modules;
      //
      const departmentRoute = department.route;
      // console.log(modules);

      // eslint-disable-next-line camelcase, no-plusplus
      for (
        let module_index = 0;
        module_index < modules.length;
        module_index++
      ) {
        const module = modules[module_index];

        const departmentName =
          department.name.charAt(0).toLowerCase() +
          department.name.substring(1, department.name.length).toLowerCase();

        const moduleName =
          module.ressource.charAt(0).toUpperCase() +
          module.ressource.substring(1, module.ressource.length).toLowerCase();

        let moduleFolder = "";
        if (module.folder == true) {
          moduleFolder = module.ressource + "/";
        }

        if (module.ressource != null && module.module != null) {
          if (module.folder == true) {
            // check permissions
            if (isset(module.list) && module.list == true) {
              routesFormated.push(
                {
                  path: "/" + departmentRoute + "/" + module.ressource,
                  name: module.ressource,
                  component: () =>
                    import(
                      "@/views/" +
                        departmentRoute +
                        "/" +
                        moduleFolder +
                        "Index.vue"
                    ),
                  meta: {
                    resource: module.ressource,
                    pageTitle: module.module,
                    pageIcon: department.icon,
                  },
                },
                {
                  path: "/" + departmentRoute + "/" + module.ressource,
                  name: module.ressource + "-list",
                  component: () =>
                    import(
                      "@/views/" +
                        departmentRoute +
                        "/" +
                        moduleFolder +
                        "Index.vue"
                    ),
                  meta: {
                    resource: module.ressource,
                    pageTitle: module.module,
                    pageIcon: department.icon,
                  },
                }
              );
            }
            if (isset(module.add) && module.add == true) {
              routesFormated.push({
                path:
                  "/" +
                  departmentRoute +
                  "/" +
                  module.ressource +
                  "/" +
                  module.ressource +
                  "-add-new",
                name: module.ressource + "-add-new",
                component: () =>
                  import(
                    "@/views/" +
                      departmentRoute +
                      "/" +
                      module.ressource +
                      "/Controller.vue"
                  ),
                meta: {
                  pageTitle: department.name,
                  pageIcon: department.icon,
                  //{ acl
                  resource: module.ressource,
                  action: "read",
                  //}
                  breadcrumb: [
                    {
                      text: moduleName + "s",
                    },
                    {
                      text: "Ajouter",
                      active: true,
                    },
                  ],
                },
              });
            }
            if (isset(module.view) && module.view == true) {
              routesFormated.push({
                path:
                  "/" + departmentRoute + "/" + module.ressource + "-view/:id",
                name: module.ressource + "-view",
                component: () =>
                  import(
                    "@/views/" +
                      departmentRoute +
                      "/" +
                      module.ressource +
                      "/Controller.vue"
                  ),
                meta: {
                  pageTitle: departmentName,
                  pageIcon: department.icon,
                  //{ acl
                  resource: module.ressource,
                  action: "read",
                  //}
                  breadcrumb: [
                    {
                      text: moduleName + "s",
                    },
                    {
                      text: "Infos " + moduleName,
                      active: true,
                    },
                  ],
                },
              });
            }
            if (isset(module.update) && module.update == true) {
              routesFormated.push({
                path:
                  "/" + departmentRoute + "/" + module.ressource + "-edit/:id",
                name: "" + module.ressource + "-edit",
                component: () =>
                  import(
                    "@/views/" +
                      departmentRoute +
                      "/" +
                      module.ressource +
                      "/Controller.vue"
                  ),
                meta: {
                  pageTitle: department.name,
                  pageIcon: department.icon,
                  //{ acl
                  resource: module.ressource,
                  action: "read",
                  //}
                  breadcrumb: [
                    {
                      text: moduleName + "s",
                    },
                    {
                      text: "Modifier " + moduleName,
                      active: true,
                    },
                  ],
                },
              });
            }
            if (isset(module.delete) && module.delete == true) {
              // routesFormated.push();
            }
            if (isset(module.monitoring) && module.monitoring == true) {
              routesFormated.push({
                path:
                  "/" +
                  departmentRoute +
                  "/" +
                  module.ressource +
                  "-monitoring/:id",
                name: module.ressource + "-monitoring",
                component: () =>
                  import(
                    "@/views/" +
                      departmentRoute +
                      "/" +
                      module.ressource +
                      "/Monitoring.vue"
                  ),
                meta: {
                  pageTitle: departmentName,
                  pageIcon: department.icon,
                  //{ acl
                  resource: module.ressource,
                  action: "read",
                  //}
                  breadcrumb: [
                    {
                      text: moduleName + "s",
                    },
                    {
                      text: "Suivi " + moduleName,
                      active: true,
                    },
                  ],
                },
              });
            }
            // add action routes end
          } else {
            // module without folder
            if (isset(module.view) && module.view == true) {
              routesFormated.push({
                path: "/" + departmentRoute + "/" + module.ressource,
                name: module.ressource,
                component: () =>
                  import(
                    "@/views/" +
                      departmentRoute +
                      "/" +
                      moduleFolder +
                      moduleName +
                      ".vue"
                  ),
                meta: {
                  pageTitle: module.module,
                  pageIcon: department.icon,
                  //{ acl
                  resource: module.ressource,
                  action: "read", 
                  breadcrumb: [
                    {
                      text: module.module,
                      active: true,
                    },
                  ],
                },
              });
            }
          }
        }
      }
    }
  }
} else {
  // not loggedin
  // window.location = "/";
  // redirect to login page
}
// system routes

// export
export default routesFormated;
