import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

// mode production
import _routes from "./routes/_routes"; 

// mode maintenance
// -----------------------------------------------------------
import elements from "./routes/ui-elements/index";

/* import system from "./routes/system";
   import administration from "./routes/administration";
   import dashboard from "./routes/dashboard"; */
// import pages from "./routes/pages";
// import others from "./routes/others";
// import chartsMaps from "./routes/charts-maps";
// import elements from "./routes/ui-elements/ui";
// import forms from "./routes/forms-tables";

Vue.use(VueRouter);

// _routes
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  // routes: _routes, // mode production
  // mode maintenance  
  routes: [ ..._routes, ...elements ]
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
