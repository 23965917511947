import mock from "@/@fake-db/mock";
/* eslint-disable global-require */

const data = {
  accountSetting: {
    general: {
      avatar: require("@/assets/images/portrait/small/avatar-s-11.jpg"),
      corporation_name: "CBP",
      fullName: "CBP Admin",
      email: "admin@kamite.com",
      company: "Kamite Dev Team",
    },
    official: {
      legal: "",
      idnat: "",
      rccm: "",
      impot: "",
    },
    info: {
      bio: "",
      dob: "Admin",
      country: "DRC",
      website: "cbp.alindae.com",
      phone: 243995812821,
    },
    social: {
      socialLinks: {
        twitter: "https://www.twitter.com",
        facebook: "",
        google: "",
        linkedIn: "https://www.linkedin.com",
        instagram: "",
        quora: "",
      },
      connections: {
        twitter: {
          profileImg: require("@/assets/images/avatars/11-small.png"),
          id: "elikarkanane",
        },
        google: {
          profileImg: require("@/assets/images/avatars/3-small.png"),
          id: "elikarkanane",
        },
        facebook: {},
        github: {},
      },
    },
    notification: {
      commentOnArticle: true,
      AnswerOnForm: true,
      followMe: false,
      newAnnouncements: true,
      productUpdates: true,
      blogDigest: false,
    },
  },
};
/* eslint-disable global-require */
mock.onGet("/account-setting/data").reply(() => [200, data.accountSetting]);
