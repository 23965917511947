import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import AnimatedLoadingIcon from "@core/layouts/components/Loading.vue";
// Form Creator
import ElementUI from "element-ui"; //引入element-ui库
import VForm from "vform-builds"; //引入VForm库
import axios from "axios"; //如果需要axios，请引入

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Print
import VueHtmlToPaper from "vue-html-to-paper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Form Creator
import "element-ui/lib/theme-chalk/index.css"; //引入element-ui样式
import "vform-builds/dist/VFormDesigner.css"; //引入VForm样式

Vue.use(ElementUI); //全局注册element-ui
Vue.use(VForm); //全局注册VForm(同时注册了v-form-designe、v-form-render等组件)

window.axios = axios;

// Axios Mock Adapter
import "@/@fake-db/db";

// # Instruct Vue to use it
import LaravelPermissionToVueJS from "laravel-permission-to-vuejs";
Vue.use(LaravelPermissionToVueJS);

var token = localStorage.getItem("token") ? localStorage.getItem("token") : "";
var baseUrl = "http://127.0.0.1:8000/api/";

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

var myApi = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: { Authorization: `Bearer ${token}` },
});
// Interceptor begin
myApi.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

myApi.interceptors.response.use(
  function (response) {
    console.log("---Interceptor response ---");
    console.log(response);
    // Any status code within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // console.log("---Interceptor error ---");
    if (error.response.status == 401) {
      // session expired
      console.log(error);
      // Remove userData from localStorage
      // deconnect

      // Remove userData from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("token");

      // console.log(router);

      // Redirect to login page
      localStorage.setItem("last_route_name", window.location.href);
      router.push({ name: "auth-login" });
      // console.log(", query: { 'redirect': route.name }");
    }
    // console.log(error);

    // Any status codes outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
// Interceptor end
// export default api
window.myApiBaseUrl = baseUrl;
window.myApi = myApi;

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// set default permissions
// window.Laravel.jsPermissions = [];
/* console.log('window.Laravel');
console.log(window.Laravel); */
var special_permissions = localStorage.getItem("special_permissions")
  ? localStorage.getItem("special_permissions")
  : [];
window.vueSpatiePermissions = special_permissions;

// loading icon global registration
Vue.component('AnimatedLoadingIcon', AnimatedLoadingIcon);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

// printing
// or, using the defaults with no stylesheet

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
};

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false;

let vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  beforeCreate: function () {
    console.log("Before create vuejs app... perform get config")
  },
  data: {
    localTime: " "
  },
  methods: {
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
    sendMessageQueue: async function () { 
      // var time = this;
      var vue_instance = this; 
      await setInterval(function () {
        /* time.localTime = new Date().toLocaleTimeString();
        console.log(time) */ 

        let token = localStorage.getItem("token");
        if (token != null && token != undefined) {

          myApi({
            url: "msgtask",
            method: "GET",
          })
            .then((response) => {
              console.log(response)
              localStorage.setItem("msgtasktimeout", response.data.msgtasktimeout);
              /* vue_instance.showToast(
                "Notification",
                response.data.message,
                "success",
                "CheckIcon",
                "center",
                3000
              ); */
            })
            .catch((error) => {
              console.log(error)
              /* vue_instance.showToast(
                "Notification",
                "Erreur lors d'envoie des notifications",
                "warning",
                "InfoIcon",
                "center",
                5000
              ); */
            });

        }

      }, localStorage.getItem("msgtasktimeout"));
    },
  },
  mounted() {
    
    localStorage.setItem("msgtasktimeout", 60000);
    this.sendMessageQueue()

  }
}).$mount("#app");

// export default instance;
